import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const AboutPage = () => (
  <Fragment>
    <Helmet>
      <title>About us | README Digital</title>
    </Helmet>
    <section className="h-[280px] bg-[url('../images/about-page/banner.png')] bg-center bg-contain flex items-center">
      <div className="container">
        <h1 className="text-[42px] font-bold">About Us</h1>
      </div>
    </section>
    <section>
      <div className="container text-center pt-[80px]">
        <h2 className="text-[42px] font-bold mb-8">
          <span className="text-[#F13D6F]">Who</span> We Are
        </h2>
        <p className="text-[17px] font-bold leading-[170.4%] xl:w-[930px] xl:mx-auto">
          Every company, big or small, began to mention "Digital Transformation" these days, and it
          is becoming more and more important how we can incorporate the world's latest technology
          into our businesses. However, many of the state-of-the-art cloud products are extremely
          rarely localized to languages other than English, and the local business engineers always
          get frustrated with English docs of such technology products.
          <br />
          README Digital is a series of localized documentation building services that help the
          business and users take the first step toward DX.
        </p>
      </div>
    </section>
    <section>
      <div className="container text-center py-[80px]">
        <StaticImage
          src="../images/about-page/digital-research.jpg"
          alt="Digital Research"
          className="mb-8 w-[300px]"
        />
        <p className="text-[17px] font-bold mb-[20px] xl:w-[930px] xl:mx-auto">
          Established in 2015 in Tokyo, Japan, Digital Research Corporation is a digital technology research and media
          company whose mission is to become a bridge between
          advanced technology companies in the US/Europe and the Japanese/Asian markets. We provide
          the latest information on global digital services through a variety of channels such as
          media, research, articles, and events.
        </p>
        <a
          className="underline text-[#F13D6F] text-[20px] mb-[50px] inline-block"
          href="https://digitalresearch.net"
          target="_blank"
          rel="noreferer"
        >
          https://digitalresearch.net
        </a>
        <br/>
        <Link
          to="/contact"
          className="p-2 text-white inline-flex items-center justify-center w-[140px] bg-gradient-to-r from-[#F13D6F] to-[#FF7C01] rounded-[20px]"
        >
          Contact Us
        </Link>
      </div>
    </section>
  </Fragment>
);

export default AboutPage;
